exports.components = {
  "component---src-pages-404-tsx": () => import("./../../../src/pages/404.tsx" /* webpackChunkName: "component---src-pages-404-tsx" */),
  "component---src-pages-event-event-season-event-route-event-date-tsx": () => import("./../../../src/pages/event/{event.season}/{event.route}-{event.date}.tsx" /* webpackChunkName: "component---src-pages-event-event-season-event-route-event-date-tsx" */),
  "component---src-pages-event-event-season-tsx": () => import("./../../../src/pages/event/{event.season}.tsx" /* webpackChunkName: "component---src-pages-event-event-season-tsx" */),
  "component---src-pages-event-index-tsx": () => import("./../../../src/pages/event/index.tsx" /* webpackChunkName: "component---src-pages-event-index-tsx" */),
  "component---src-pages-index-tsx": () => import("./../../../src/pages/index.tsx" /* webpackChunkName: "component---src-pages-index-tsx" */),
  "component---src-pages-loneliness-index-tsx": () => import("./../../../src/pages/loneliness/index.tsx" /* webpackChunkName: "component---src-pages-loneliness-index-tsx" */),
  "component---src-pages-shop-audax-a-distance-tsx": () => import("./../../../src/pages/shop/audax-a-distance.tsx" /* webpackChunkName: "component---src-pages-shop-audax-a-distance-tsx" */),
  "component---src-pages-shop-medals-tsx": () => import("./../../../src/pages/shop/medals.tsx" /* webpackChunkName: "component---src-pages-shop-medals-tsx" */),
  "component---src-pages-symposium-2020-tsx": () => import("./../../../src/pages/symposium/2020.tsx" /* webpackChunkName: "component---src-pages-symposium-2020-tsx" */),
  "component---src-pages-symposium-2021-tsx": () => import("./../../../src/pages/symposium/2021.tsx" /* webpackChunkName: "component---src-pages-symposium-2021-tsx" */),
  "component---src-templates-newsletter-tsx": () => import("./../../../src/templates/Newsletter.tsx" /* webpackChunkName: "component---src-templates-newsletter-tsx" */)
}

